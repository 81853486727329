import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import { navigate } from "gatsby"
import clsx from "clsx"
import VerifyCode from "@ecom/ui/components/VerifyCode"

import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"

import HelperText from "./HelperText"

import { getManyIDBValues, setIDBValue } from "../../utils/idbUtils"
import { sendRequest } from "../../helpers/sendRequest"
import { sendDataLayerPush } from "./helpers/sendDataLayerPush"

import * as styles from "./newVerifySMS.module.scss"

const TIMER_START = 45

interface IVerifySMS {
  setWidthGreenBar: (percent: number) => void
}

export const NewVerifySMS = ({ setWidthGreenBar }: IVerifySMS) => {
  const [userId, setUserId] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [time, setTime] = useState(TIMER_START)
  const [errorMsg, setErrorMsg] = useState("Неверный код")
  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState<number | null>(null)
  const [isSending, setIsSending] = useState(false)
  const [isShowPhoneField, setIsShowPhoneField] = useState(false)
  const [isShowHint, setIsShowHint] = useState(false)
  const location = useLocation()

  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isShowPhoneField) {
      parentRef?.current?.querySelector("input")?.focus()
    }
  }, [isShowPhoneField])

  useEffect(() => {
    getManyIDBValues(["phone", "requestId"]).then(([phone, requestId]) => {
      if (requestId) {
        setUserId(requestId)
      }
      if (phone) {
        setUserPhone(phone)
      }
    })
  }, [])

  useEffect(() => {
    let timerID: ReturnType<typeof setInterval>
    if (time > 0) {
      timerID = setInterval(() => {
        setTime(time - 1)
      }, 1000)
    }
    return () => clearTimeout(timerID)
  })

  function getNewCode() {
    setCode(null)
    setIsError(false)
    sendDataLayerPush("resend")
    sendRequest(`/v2/app/public/phone/validate/${userId}`, {}, true)
      .then(() => {
        setTime(TIMER_START)
      })
      .catch(() => {
        setTime(0)
        setIsError(true)
        setErrorMsg("Превышен лимит запросов")
      })
  }
  function submitCode(smsCode: number) {
    // Первый апи для длинной (haveCourier=true)
    sendRequest(
      location?.state?.haveCourier
        ? `/v2/app/public/halva/phone/validate/${userId}`
        : `/v2/app/public/phone/validate/${userId}`,
      { code: smsCode },
      true
    )
      .then(({ result }) => {
        setIsSending(true)
        setIsShowPhoneField(false)
        sendDataLayerPush(result)

        if (result !== "validated") {
          setIsError(true)
          setIsSending(false)
        }

        switch (result) {
          case "notValid":
            setErrorMsg("Не правильно введен код")
            break
          case "exceeded":
            setErrorMsg("Время кода истекло")
            break
          case "timeout":
            setErrorMsg("Превышен лимит запросов")
            break
          default:
            break
        }

        if (result === "validated") {
          setWidthGreenBar(90)
          if (location?.state?.haveCourier) {
            navigate("/choose-pickup-or-delivery/", {
              state: { thanksPage: location.state.thanksPage },
            })
          } else {
            navigate(location.state.thanksPage ? location.state.thanksPage : "/success/")
          }
        }
      })
      .catch(() => {
        setIsError(true)
        navigate("/error-technical/")
      })
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = e

    if (value === "") {
      setCode(null)
    } else {
      setCode(+value)
    }

    if (isError) {
      setIsError(false)
    }

    if (value.length === 4) {
      submitCode(+value)
    }
  }

  function showPhoneField() {
    setIsShowPhoneField(true)
  }

  function closeAlert() {
    setIsShowHint(false)
  }

  function handleSubmit({ phone }: { phone: string }) {
    setIsShowPhoneField(false)
    setIDBValue("phone", phone)
    setTime(TIMER_START)
    sendRequest(`/v2/app/public/phone/validate/${userId}`, { phone }, true).then(() => {
      try {
        setUserPhone(phone)
        setErrorMsg("")
        setIsError(false)
        setIsShowHint(true)
        sendDataLayerPush("resend")
      } catch (error) {
        setIsError(true)
        setErrorMsg("При вводе номера произошла ошибка")
      }
    })
  }

  return (
    <div className="text-center">
      <h2 className={styles.head}>Введите код из СМС</h2>
      {userPhone ? (
        <p className={clsx(styles.description, styles.hintSended)}>
          Мы отправили код подтверждения
          <br />
          на номер <span>{userPhone}</span>
        </p>
      ) : null}
      <div className={styles.wrapInputVerify} ref={parentRef}>
        <VerifyCode
          autoFocus
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleChange}
          value={code}
          error={isError}
          errorMsg={errorMsg}
          disabled={isSending}
          helperText={<HelperText isRunning={time !== 0} timer={time} onClick={getNewCode} />}
          classes={{
            verifyCodeInput: styles.input,
            errorTextRoot: styles.errText,
            formControlRoot: styles.verifyCode,
          }}
        />
      </div>
      {isShowPhoneField ? (
        <FormLanding name="verifySMSForm" onSubmit={handleSubmit}>
          <PhoneField name="phone" label="Телефон" style={{ width: 290 }} />
          <SubmitButton style={{ width: 290 }}>Отправить</SubmitButton>
        </FormLanding>
      ) : (
        <p className={styles.description}>
          Ошиблись при вводе номера?
          <br />
          <button className={styles.btnCorrect} onClick={showPhoneField} type="button">
            Исправьте
          </button>{" "}
          его, и мы вышлем
          <br />
          подтверждение снова
        </p>
      )}
      <Snackbar
        open={isShowHint}
        onClose={closeAlert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert elevation={6} variant="filled" severity="info">
          Телефон обновлен
        </Alert>
      </Snackbar>
    </div>
  )
}
