import React from "react"

import * as styles from "./miniStaticPaymentSchedule.module.scss"

import { IPhoneImg, HalvaBg } from "../../img"

import { paymentSchedule } from "./data"
import { TMiniStaticPaymentScheduleProps } from "./type"

export const MiniStaticPaymentSchedule = ({
  logo = <IPhoneImg alt="iPhone logo" />,
}: TMiniStaticPaymentScheduleProps) => (
  <>
    <div className={styles.popup}>
      <div className={styles.descr}>Стоимость</div>
      <div className={styles.price}>79 990 ₽</div>
    </div>
    {logo}
    <div className={styles.paymentBlock}>
      <div className={styles.title}>График платежей</div>
      <ul className={styles.items}>
        {paymentSchedule.map((item) => (
          <li className={styles.item} key={item.mouth}>
            <div className={styles.itemTitle}>{item.price}</div>
            <p className={styles.itemDesc}>
              {item.mouth} {item.span && item.span}
            </p>
          </li>
        ))}
      </ul>
      <hr />
      <div className={styles.totalBlock}>
        <div>
          <div className={styles.descr}>Итого</div>
          <div className={styles.price}>79 990 ₽</div>
        </div>
        <div className={styles.wrap}>
          <span>0 ₽</span> Переплата
        </div>
      </div>
    </div>
    <HalvaBg className={styles.halvaBgLogo} objectPosition="top left" alt="halva background logo" />
  </>
)
