// extracted by mini-css-extract-plugin
export var descr = "miniStaticPaymentSchedule-module--descr--gsm+J";
export var halvaBgLogo = "miniStaticPaymentSchedule-module--halvaBgLogo--rc6ys";
export var item = "miniStaticPaymentSchedule-module--item--FsvHx";
export var itemDesc = "miniStaticPaymentSchedule-module--itemDesc--Rgzi3";
export var itemTitle = "miniStaticPaymentSchedule-module--itemTitle--6iPrJ";
export var items = "miniStaticPaymentSchedule-module--items--aANkQ";
export var paymentBlock = "miniStaticPaymentSchedule-module--paymentBlock--pUVIy";
export var popup = "miniStaticPaymentSchedule-module--popup--1pAmp";
export var price = "miniStaticPaymentSchedule-module--price--i0Ifs";
export var title = "miniStaticPaymentSchedule-module--title--DLr5p";
export var totalBlock = "miniStaticPaymentSchedule-module--totalBlock--l1eO5";
export var wrap = "miniStaticPaymentSchedule-module--wrap--vfj-D";