import React from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"

import * as styles from "./InstallmentExample.module.scss"
import { additionalDescriptionDefault, additionalTitleDefault } from "./data"
import { InstallmentExampleProps } from "./types"
import { MiniStaticPaymentSchedule } from "./Content/MiniStaticPaymentSchedule/MiniStaticPaymentSchedule"

export const InstallmentExample = ({
  content = <MiniStaticPaymentSchedule />,
  title = "Пример рассрочки",
  text = "iPhone 14 256 ГБ",
  orderNum,
  additionalDescription = additionalDescriptionDefault,
  additionalTitle = additionalTitleDefault,
  withAdditionalBlock = false,
  variantStyle,
}: InstallmentExampleProps) => (
  <div
    className={clsx(styles.section, variantStyle && styles[variantStyle])}
    data-exclude={orderNum}
  >
    <Container className={styles.container}>
      {withAdditionalBlock && (
        <div className={styles.additionalBlock}>
          <h2 className={styles.additionalTitle}>{additionalTitle}</h2>
          <div className={styles.additionalDescription}>{additionalDescription}</div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.textBlock}>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.descr}>{text}</p>
        </div>
        {content}
      </div>
    </Container>
  </div>
)
