import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { navigate } from "gatsby"

import AutocompleteRegionByPhoneField from "@ecom/ui/components/FormLanding/fields/AutocompleteRegionByPhoneField"
import DadataAddress from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataAddress"
import getKladrIds from "@ecom/ui/helpers/getKladrIds"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { useLocation } from "@gatsbyjs/reach-router"
import { validateAddressWithIcons, validateRegion } from "../../helpers/validators"
import { LongAppWrapper } from "../LongAppWrapper"
import type { FieldData } from "../../interfaces/fieldData"
import type { IDadataAddress } from "../../interfaces/dadata"
import { EMPTY_REGION_VALUE, formatValuesToRequest, LOCALITY_DADATA_OPTIONS } from "./helpers"
import type { LongAppFormData, LongAppFormDataFormatted, RegionData } from "./types"
import { sendRequest } from "../../helpers/sendRequest"
import { getManyIDBValues, setIDBValue } from "../../utils/idbUtils"
import { events } from "../../helpers/events"
import type { HalvaResponse } from "../../interfaces/responseStatus"

import * as styles from "./ChooseLocalityForm.module.scss"

const ChooseLocalityForm = () => {
  const [region, setRegion] = useState<RegionData>(EMPTY_REGION_VALUE)
  const [phone, setPhone] = useState("")
  const [requestId, setRequestId] = useState("")
  const [validation, setValidation] = useState({
    region: {
      validated: false,
      isValid: false,
    },
    address: {
      validated: false,
      isValid: false,
    },
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const location = useLocation()

  const refForm = useRef<any>(null)

  useEffect(() => {
    getManyIDBValues(["requestId", "phone"]).then(([requestIdVal, phoneVal]) => {
      setRequestId(requestIdVal)
      setPhone(phoneVal)
    })
  }, [])

  const dadataOptions = {
    ...LOCALITY_DADATA_OPTIONS,
    locations: getKladrIds(region.kladr_id),
  }

  const onChangeFields = {
    region: ({ value }: FieldData<RegionData>) => {
      if (!value) {
        refForm.current.setField("localityAddress", { value: "" })
        setRegion(EMPTY_REGION_VALUE)
        setValidation((prev) => ({
          ...prev,
          region: {
            validated: false,
            isValid: false,
          },
        }))
        return
      }

      const newRegionValue = value
      const prevRegionValue = region

      setRegion(newRegionValue)
      setIDBValue("region", newRegionValue)
      setValidation((prev) => ({
        ...prev,
        region: {
          validated: true,
          isValid: true,
        },
      }))
      if (!newRegionValue.value || newRegionValue.kladr_id !== prevRegionValue.kladr_id) {
        refForm.current.setField("localityAddress", { value: "" })
      }
    },
  }

  const checkIsValid = (dadataAddress: IDadataAddress) =>
    validateAddressWithIcons(dadataAddress, setValidation)

  const checkIsValidRegion = (data: LongAppFormData) => validateRegion(data, setValidation)

  async function checkDelivery(values: LongAppFormDataFormatted) {
    const { city_kladr_id, settlement_kladr_id } = values?.localityAddress?.data || {}

    try {
      const { PUBLIC_ADDRESS, HOME_ADDRESS, REGISTRATION_ADDRESS, WORK_ADDRESS } =
        await sendRequest(`/v2/app/public/halva/delivery`, {
          work: city_kladr_id || settlement_kladr_id,
          home: city_kladr_id || settlement_kladr_id,
          registration: city_kladr_id || settlement_kladr_id,
          public: city_kladr_id || settlement_kladr_id,
        })

      return PUBLIC_ADDRESS || HOME_ADDRESS || REGISTRATION_ADDRESS || WORK_ADDRESS
    } catch {
      return null
    }
  }

  async function handleSubmit(values: any) {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Дальше",
      placement: "appl_halva",
      eventAction: "chooseLocalityForm",
      eventCategory: "sendForm",
    })

    setIsSubmitting(true)

    const formattedValues = formatValuesToRequest(values)
    const isDeliveryAvailable = await checkDelivery(formattedValues)
    sendRequest(`/v2/app/public/halva/${requestId}`, { ...formattedValues }, true)
      .then(({ status, apiId }: HalvaResponse) => {
        switch (status) {
          case "reject":
            navigate("/error-reject")
            pushToDataLayer(events.applicationSend.reject)
            return
          case "error":
            throw Error(status)
          default:
            break
        }

        setIDBValue("requestId", apiId)
      })
      .then(() => {
        if (isDeliveryAvailable) {
          navigate("/passportRedesign/", {
            state: { thanksPage: location.state.thanksPage },
          })
        } else {
          navigate("/long-form-success-not-courier/")
        }
      })
      .catch(() => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        navigate("/error-technical/")
      })
  }

  return (
    <LongAppWrapper
      title={
        <h2 className={clsx(styles.formHead, styles.main)}>Выберите ваш населенный&nbsp;пункт</h2>
      }
      onChangeFields={onChangeFields}
      refForm={refForm}
      handleSubmit={handleSubmit}
      isDisabled={isSubmitting}
    >
      <div className={styles.longContainerFluid}>
        <div className={styles.input}>
          <div className={styles.region}>
            <AutocompleteRegionByPhoneField
              label="Регион"
              name="region"
              validate={checkIsValidRegion}
              phone={phone}
            />
          </div>
          {validation.region.validated && (
            <div
              className={clsx(
                styles.icon,
                validation.region.isValid ? styles.valid : styles.invalid
              )}
            />
          )}
        </div>
        {region.value && (
          <div className={styles.input}>
            <div className={styles.region}>
              <DadataAddress
                data-testid="localityAddress"
                label="Населенный пункт"
                name="localityAddress"
                dadataOptions={dadataOptions}
                validate={checkIsValid}
              />
            </div>
            {validation.address.validated && (
              <div
                className={clsx(
                  styles.icon,
                  validation.address.isValid ? styles.valid : styles.invalid
                )}
              />
            )}
          </div>
        )}
      </div>
    </LongAppWrapper>
  )
}

export default ChooseLocalityForm
