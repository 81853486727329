import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"
import { TypeBannerProps } from "../helpers"
import { useObserver } from "../../../hooks/useObserver"

const defaultSubtext = `
— до 36 месяцев рассрочки <br/>
— до 5000 Р кэшбэка <br/>
— ценные подарки от партнёров
`

export function Banner({
  buttonText = "Оформить карту",
  description = defaultSubtext,
  orderNum,
}: TypeBannerProps) {
  const isVisible = useObserver(["formPkw", "calculator", "bottomCta"])
  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <p className={styles.someDate}>09.12-12.01</p>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1>
                Новогодний бум <br />с Халвой
              </h1>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
