export const paymentSchedule = [
  { price: "0 ₽", mouth: "Месяц", span: "покупки" },
  { price: "7 999 ₽", mouth: "1 месяц" },
  { price: "7 999 ₽", mouth: "2 месяц" },
  { price: "7 999 ₽", mouth: "3 месяц" },
  { price: "7 999 ₽", mouth: "4 месяц" },
  { price: "7 999 ₽", mouth: "5 месяц" },
  { price: "7 999 ₽", mouth: "6 месяц" },
  { price: "7 999 ₽", mouth: "7 месяц" },
  { price: "7 999 ₽", mouth: "8 месяц" },
  { price: "7 999 ₽", mouth: "9 месяц" },
  { price: "7 999 ₽", mouth: "10 месяц" },
]
