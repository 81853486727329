import sendRequest from "@ecom/ui/utils/sendRequest"

interface PartnersTerms {
  month: number
  description: string
}

export interface Partner {
  brand: string
  description: string
  icon_url: string
  id: number
  partner_site_url: string
  period: number
  site: string
  terms: PartnersTerms[]
  urlName: string
}

interface Response {
  errorCode: number
  result: Partner[]
  status: number
}

const limit = 10

export default function fetchPartners(offset = 0): Promise<Partner[]> {
  return sendRequest(`${process.env.GATSBY_APP_HALVA_API_URL}/v2/halva/shop`, {
    limit,
    offset,
  }).then((res: Response) => {
    if (res.status === 1) {
      return res.result
    }
    throw new Error("Fetch partners failed")
  })
}
