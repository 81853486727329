import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import clsx from "clsx"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

import { TypeBannerPropsWithElements } from "../helpers"
import { useObserver } from "../../../hooks/useObserver"

export function Banner({
  buttonText = "Оформить карту",
  orderNum,
  description,
  title,
}: TypeBannerPropsWithElements) {
  const isVisible = useObserver(["formPkw", "calculator", "bottomCta"])
  return (
    <>
      <section data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1>{title}</h1>
              <div>{description}</div>
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
