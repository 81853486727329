import React, { ReactNode } from "react"

import Grid from "@ecom/ui/components/Grid"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import { FirstCard, SecondCard, FirstCardHalvaVklad, SecondCardHalvaVklad } from "./img"
import * as styles from "./otherCards.module.scss"
import { SecondCardBG } from "./img/SecondCardBG"

interface IOtherCards {
  title?: ReactNode
  firstCardText?: ReactNode
  secondCardText?: ReactNode
  orderNum?: string
  isHalvaVklad?: boolean
  variantStyle?: "halvaVklad"
}

const OtherCards = ({
  title = "Рефинансируйте другие кредитные карты",
  firstCardText = "Переведите долги по кредитным картам до 300 000 ₽ на Халву",
  secondCardText = "Наслаждайтесь рассрочкой 24 месяца",
  orderNum,
  isHalvaVklad = false,
  variantStyle,
}: IOtherCards) => (
  <section
    className={clsx(styles.otherCards, variantStyle && styles[variantStyle])}
    data-exclude={orderNum}
  >
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={10} sm={8} md={5}>
          <h2>{title}</h2>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={styles.cardsBlock}>
        <Grid className={styles.firstCard} item xs={12} sm={6}>
          <h3>{firstCardText}</h3>
          {isHalvaVklad ? (
            <FirstCardHalvaVklad alt="карта Совкомбанк" className={styles.firstImageHalvaVklad} />
          ) : (
            <FirstCard alt="карта Совкомбанк" className={styles.firstImage} />
          )}
        </Grid>
        <Grid className={styles.secondCard} item xs={12} sm={6}>
          <h3 className={styles.secondcardTitle}>{secondCardText}</h3>
          {isHalvaVklad ? (
            <SecondCardHalvaVklad alt="карта Совкомбанк" className={styles.secondImageHalvaVklad} />
          ) : (
            <>
              <SecondCardBG alt="" className={styles.secondBG} />
              <SecondCard alt="карта Совкомбанк" className={styles.secondImage} />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  </section>
)

export default OtherCards
