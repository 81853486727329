import React, { useState, ReactNode } from "react"

import Container from "@ecom/ui/components/Container"

import { StaticImage } from "gatsby-plugin-image"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import clsx from "clsx"
import { PersonalForm } from "./Form/PersonalForm"

import * as styles from "./form.module.scss"

const DEFAULT_TITLE = (
  <>
    Получите карту рассрочки <br /> с доставкой или в офисе
  </>
)

export type TFormProps = {
  isGreenApprove?: boolean
  dataLayerName?: string
  formBtnText?: string
  orderNum?: string
  title?: ReactNode
  subtitle?: ReactNode
  noWoman?: boolean
  variant?: "refin" | "standard"
  progressBar?: boolean
}

export function Form({
  dataLayerName = "shortPersonalForm",
  formBtnText,
  orderNum,
  title = DEFAULT_TITLE,
  subtitle,
  noWoman = false,
  variant = "standard",
  isGreenApprove = false,
  progressBar = false,
}: TFormProps) {
  const [clickOnInput, setClickOnInput] = useState(false)

  const handleClick = (e: any) => {
    if (!clickOnInput && e.target.nodeName === "INPUT") {
      pushToDataLayer({
        event: "Start_filling",
        eventLabel: globalThis?.location?.href,
      })
      setClickOnInput(true)
    }
  }

  return (
    <section id="form" className={clsx(styles.section, styles[variant])} data-exclude={orderNum}>
      <div className={styles.anchor} id="app" />
      <Container>
        <div className={clsx(styles.form, styles[variant])}>
          <div className={styles.info}>
            <h2 className={clsx(styles.head, styles[variant])}>{title}</h2>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>

          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={styles.formContainer}
            onClick={(e) => handleClick(e)}
            onKeyDown={(e) => handleClick(e)}
          >
            <PersonalForm
              progressBar={progressBar}
              isGreenApprove={isGreenApprove}
              dataLayerName={dataLayerName}
              formBtnText={formBtnText}
            />
          </div>
          {!noWoman && (
            <StaticImage
              className={styles.img}
              alt="women"
              src="./images/women.png"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
            />
          )}
        </div>
      </Container>
    </section>
  )
}
