import React from "react"
import EmploymentRedesign from "../components/EmploymentRedesign"
import { NewHeader } from "../components/Headers/NewHeader"

import Layout from "../components/Layouts/secondaryPage"

export default function EmploymentPage() {
  return (
    <div className="bg-wrapper">
      <Layout>
        <NewHeader noRightSection noShadow={false} withButton={false} />
        <EmploymentRedesign />
      </Layout>
    </div>
  )
}
