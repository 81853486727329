import React from "react"

import clsx from "clsx"
import { LaptopLogo, HalvaBg } from "../../img"

import { TExampleWithLaptopProps } from "./type"

import * as styles from "./exampleWithLaptop.module.scss"

export const ExampleWithLaptop = ({
  logo = <LaptopLogo alt="laptop logo" />,
  monthlyPayment = "2 595 ₽",
  installmentPeriod = "до 36 месяцев",
  totalCost = "93 390 ₽",
  variantStyle,
}: TExampleWithLaptopProps) => (
  <>
    <div className={clsx(styles.exampleBlock, variantStyle && styles[variantStyle])}>
      <div className={styles.benefitsBlock}>
        <p className={styles.benefitsItem}>Без комисии</p>
        <p className={styles.benefitsItem}>Без переплат</p>
      </div>
      <div className={styles.monthlyPaymentBlock}>
        <p className={styles.monthlyText}>Ежемесячный платеж</p>
        <p className={styles.monthlyPaymentValue}>{monthlyPayment}</p>
      </div>
      <div className={styles.termAndTotalBlock}>
        <div className={styles.termSubblock}>
          <p className={styles.termFirstText}>Срок рассрочки</p>
          <p className={styles.termSecondText}>{installmentPeriod}</p>
        </div>
        <div className={styles.border} />
        <div className={styles.totalAmountSubblock}>
          <p className={styles.totalAmountText}>Общая стоимость</p>
          <p className={styles.totalAmountValue}>{totalCost}</p>
        </div>
      </div>
    </div>
    {logo}
    <HalvaBg className={styles.halvaBgLogo} objectPosition="top left" alt="halva background logo" />
  </>
)
