import React, { ReactNode } from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./howToGetCard.module.scss"

const STEPS = [
  {
    title: <>Оставьте заявку</>,
    subtitle: <>Онлайн за несколько минут</>,
  },
  {
    title: <>Получите карту</>,
    subtitle: <>Доставкой или в&nbsp;отделении банка</>,
  },
  {
    title: <>Активируйте карту</>,
    subtitle: <>Совершив покупку в&nbsp;партнерском магазине</>,
  },
]

type ItemsProps = {
  title: ReactNode
  subtitle: ReactNode
}

type Props = {
  orderNum?: string
  title?: ReactNode
  items?: ItemsProps[]
}

const titleDefault = (
  <>
    Как оформить карту <br className="d-md-none" /> рассрочки «Халва»
  </>
)

export const HowToGetCard = ({ orderNum, title = titleDefault, items = STEPS }: Props) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.wrapper}>
        <ul className={styles.steps}>
          {items.map((item, i) => (
            <li key={i} className={styles.step}>
              <div className={styles.stepText}>
                <p className={styles.stepTitle}>{item.title}</p>
                <p className={styles.stepSubtitle}>{item.subtitle}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </section>
)
