// extracted by mini-css-extract-plugin
export var cardsBlock = "otherCards-module--cardsBlock--op9oe";
export var firstCard = "otherCards-module--firstCard--lKQTC";
export var firstImage = "otherCards-module--firstImage--Pfmln";
export var firstImageHalvaVklad = "otherCards-module--firstImageHalvaVklad--3hNjU";
export var halvaVklad = "otherCards-module--halvaVklad--BmtSf";
export var otherCards = "otherCards-module--otherCards--bIUqf";
export var secondBG = "otherCards-module--secondBG---S2w9";
export var secondCard = "otherCards-module--secondCard--z2Kpm";
export var secondImage = "otherCards-module--secondImage--kTD0P";
export var secondImageHalvaVklad = "otherCards-module--secondImageHalvaVklad--2wH-K";