import React, { useState } from "react"
import { navigate } from "gatsby"

import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import Acceptment from "@ecom/ui/components/FormLanding/fields/AcceptmentField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"

import { fetchFormData } from "./helpers"

import * as styles from "./phoneForm.module.scss"

import type { FieldData } from "../../interfaces/fieldData"
import { events } from "../../helpers/events"

export default function PhoneForm() {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [phone, setPhone] = useState("")
  const handleChangeFields = {
    phone: ({ value }: FieldData<string>) => setPhone(value),
    agree: ({ value }: FieldData<boolean>) => setIsSubmitDisabled(!value),
  }

  const buttonText = "Получить кредит"

  const handleSubmit = () => {
    pushToDataLayer(events.buttonClick(buttonText))
    const variables = {
      phone,
      product: "КК Халва",
      product_group: "bring-back",
      additionalData: getAdditionalData(),
    }

    fetchFormData(variables)
      .then(() => {
        if ((window as any)?.ym) {
          ;(window as any).ym(process.env.GATSBY_YANDEX_ID, "reachGoal", "ApplicationSend")
        }
        pushToDataLayer(events.applicationSend.approve)
        navigate("/success-apply/")
      })
      .catch(() => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        navigate("/error-technical/")
      })
  }

  return (
    <section className={styles.formSection}>
      <Container>
        <div className={styles.formOuter} id="app">
          <FormLanding
            name="phoneForm"
            onSubmit={handleSubmit}
            placement="main"
            onChangeFields={handleChangeFields}
          >
            <h2 className={styles.title}>Введите номер телефона: </h2>
            <div className={styles.subtitle}>
              наш специалист свяжется с вами
              <br /> в ближайшее время
            </div>
            <PhoneField name="phone" />
            <Acceptment name="agree" />
            <div className="formBtnOuter">
              <SubmitButton className="formBtn" disabled={isSubmitDisabled}>
                {buttonText}
              </SubmitButton>
            </div>
          </FormLanding>
        </div>
      </Container>
    </section>
  )
}
