import React, {
  ChangeEvent,
  Dispatch,
  FocusEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import PhoneWithSeven from "@ecom/ui/components/PhoneField/PhoneWithSeven"

import { FormikTouched } from "formik"
import { WrapperDataLayer } from "./WrapperDataLayer"

import * as styles from "./fields.module.scss"
import CheckedGreenIcon from "../../../../FormPKW/Form/icons/CheckedGreen"
import Plus25Icon from "../../../../FormPKW/Form/icons/Plus25"

type Props = {
  isGreenApprove: boolean
  id: string
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPaste: (e: React.ClipboardEvent<Element>) => void
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  error?: string
  touched?: FormikTouched<{}>
  value: string
  focus: boolean
  setFocus: Dispatch<SetStateAction<boolean>>
  isFioFieldValidate?: boolean
}

export const PhoneField = ({
  touched,
  error,
  isGreenApprove,
  handleBlur,
  name,
  onPaste,
  focus,
  setFocus,
  isFioFieldValidate,
  ...restProps
}: Props) => {
  const [isFirstClick, setIsFirstClick] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  let greenIcon: JSX.Element | null = null
  if (!error && isFirstClick) {
    greenIcon = <CheckedGreenIcon />
  } else if (isFioFieldValidate || isFirstClick) {
    greenIcon = <Plus25Icon isFocusing={focus} />
  }

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focus])

  return (
    <WrapperDataLayer touched={touched} error={touched ? error || "" : ""} name={name}>
      <div
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        className={styles.fieldWrapper}
      >
        <PhoneWithSeven
          inputRef={inputRef}
          onClick={() => setIsFirstClick(true)}
          {...restProps}
          InputProps={{
            classes: {
              notchedOutline: styles.notchedOutline,
              root: styles.field,
            },
            endAdornment: <>{isGreenApprove && greenIcon}</>,
            onPaste,
          }}
          onBlur={handleBlur}
          placeholder="Телефон"
          helperText={touched ? error : ""}
          error={touched && !!error}
          fullWidth
        />
      </div>
    </WrapperDataLayer>
  )
}
