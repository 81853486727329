// extracted by mini-css-extract-plugin
export var additionalBlock = "InstallmentExample-module--additionalBlock--5Y1N7";
export var additionalDescription = "InstallmentExample-module--additionalDescription--xq5KD";
export var additionalTitle = "InstallmentExample-module--additionalTitle--DCDyy";
export var backToSchool = "InstallmentExample-module--backToSchool--5h1qa";
export var btsAuto = "InstallmentExample-module--btsAuto--nbKuH";
export var container = "InstallmentExample-module--container--Rshpi";
export var descr = "InstallmentExample-module--descr--JyO-P";
export var halvaMedia = "InstallmentExample-module--halvaMedia--csgU9";
export var section = "InstallmentExample-module--section--oAcsX";
export var textBlock = "InstallmentExample-module--textBlock--WG0Nx";
export var title = "InstallmentExample-module--title--VNgcn";
export var wrapper = "InstallmentExample-module--wrapper--bZjLD";