import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, GatsbyImageProps } from "gatsby-plugin-image"

import * as styles from "./logo.module.scss"

const imgQuery = graphql`
  {
    image: file(relativePath: { eq: "Banners/NewHalva/iphoneInShoppingCartNew.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export function IPhoneImg(props: Omit<GatsbyImageProps, "image">) {
  const data = useStaticQuery(imgQuery)

  return <GatsbyImage className={styles.iphone} {...props} image={getImage(data.image)!} />
}
