import citylink from "./img/citilink.png"
import familia from "./img/familia.png"
import goodSvyaz from "./img/good_svyaz.png"
import ildebote from "./img/ildebote.png"
import lentaOnlain from "./img/lenta_onlain.png"
import yandexEda from "./img/yandex_eda.png"
import ylibkaRadugi from "./img/ylibka_radugi.png"
import onetwotrip from "./img/onetwotrip.png"
import divanru from "./img/divanru.png"

export const partnersMain = [
  {
    id: 1,
    brand: "Пятёрочка",
    icon_url: "https://api.halvacard.ru/public-api/files/e2a3bcc9-f456-420a-8e8b-870d33e396d0.PNG",
    urlName: "pyatyorochka",
    period: 1,
  },
  {
    id: 2,
    brand: "Перекресток",
    icon_url: "https://api.halvacard.ru/public-api/files/4f1fe831-e0a9-48a4-aba9-80abd41594d8.PNG",
    urlName: "perekrestok",
    period: 1,
  },
  {
    id: 3,
    brand: "Лента",
    icon_url: "https://api.halvacard.ru/public-api/files/88dcbbdf-947d-4741-9597-3f69ab9fe4a0.jpeg",
    urlName: "lenta-magazin",
    period: 3,
  },
  {
    id: 4,
    brand: "Монетка",
    icon_url: "https://api.halvacard.ru/public-api/files/5d9d0f93-54c3-4fe5-9318-555457b93a4a.jpg",
    urlName: "monetkaru",
    period: 1,
  },
  {
    id: 6,
    brand: "Перекресток Впрок",
    icon_url: "https://api.halvacard.ru/public-api/files/937a5cc4-b421-413f-b0a3-1c6e755bac52.png",
    urlName: "perekrestokvprok",
    period: 1,
  },
  {
    id: 253,
    brand: "Лента Онлайн",
    icon_url: lentaOnlain,
    urlName: "lenta-onlajn",
    period: 2,
  },
  {
    id: 15,
    brand: "Яндекс Еда",
    icon_url: yandexEda,
    urlName: "yandeks-eda",
    period: 1,
  },
  {
    id: 7918,
    brand: "familia",
    icon_url: familia,
    urlName: "familia",
    period: 3,
  },
  {
    id: 7733,
    brand: "Ситилинк",
    icon_url: citylink,
    urlName: "citilink",
    period: 3,
  },
  {
    id: 8619,
    brand: "ИЛЬ ДЕ БОТЭ",
    icon_url: ildebote,
    urlName: "il-de-bote",
    period: 6,
  },
  {
    id: 7760,
    brand: "Хорошая Связь",
    icon_url: goodSvyaz,
    urlName: "horoshajasvjaz",
    period: 6,
  },
  {
    id: 1994,
    brand: "OneTwoTrip",
    icon_url: onetwotrip,
    urlName: "onetwotrip",
    period: 6,
  },
  {
    id: 1654,
    brand: "Улыбка Радуги",
    icon_url: ylibkaRadugi,
    urlName: "ulybka-radugi",
    period: 3,
  },
  {
    id: 1042,
    brand: "Divan.ru",
    icon_url: divanru,
    urlName: "divanru",
    period: 4,
  },
]
