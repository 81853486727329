import React from "react"

export const additionalTitleDefault = <>В чем преимущества рассрочки с Халвой?</>

export const additionalDescriptionDefault = (
  <>
    Вы не платите проценты – за вас это делает магазин. Сумма покупки делится на равные платежи –
    вносите небольшими частями каждый месяц.
    <strong> Получите увеличенный срок рассрочки с акцией «Меняем мир с Халвой»!</strong> С 28
    августа по 29 октября срок рассрочки увеличивается до 12 месяцев на покупки у всех партнеров.
  </>
)
